import Playlist from "./Playlist";
import Option from "./Option";
export default interface Screen {
    id: string;
    user_id: string;
    name: string;
    license_id: string | null;
    pair_code: string | null;

    linked?: Playlist | null;
    linked_type?: string | null;
    linked_id?: string | null;
};

export function isScreenActive(screen: Screen) {
    return screen.license_id;
}

export function isScreenActiveAndSetPlaylist(screen: Screen) {
    return screen.linked_id && screen.license_id;
}

export function isScreenNotActive(screen: Screen) {
    return screen.pair_code && screen.license_id === null;
}

export function getScreenOption(screen: Screen): Option { 
    return {
        value: screen.id,
        type: 'App\\Models\\Screen',
        label: screen.name,
    }
}
