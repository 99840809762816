export const AUTH_LOGIN             = Symbol('AUTH_LOGIN');
export const ME_LOGIN               = Symbol('ME_LOGIN');
export const AUTH_CHANGE_VALUE      = Symbol('AUTH_CHANGE_VALUE');
export const AUTH_LOGOUT            = Symbol('AUTH_LOGOUT');

export const SCREEN_FETCH           = Symbol('SCREEN_FETCH');
export const SCREEN_FETCH_NEXT      = Symbol('SCREEN_FETCH_NEXT');
export const SCREEN_CHANGE_VALUE    = Symbol('SCREEN_CHANGE_VALUE');
export const SCREEN_SHOW            = Symbol('SCREEN_SHOW');

export const EINK_FETCH           = Symbol('EINK_FETCH');
export const EINK_FETCH_NEXT      = Symbol('EINK_FETCH_NEXT');
export const EINK_CHANGE_VALUE    = Symbol('EINK_CHANGE_VALUE');
export const EINK_SHOW            = Symbol('EINK_SHOW');

export const CONFIG_FETCH           = Symbol('CONFIG_FETCH');
export const CONFIG_CHANGE_VALUE    = Symbol('CONFIG_CHANGE_VALUE');

export const SCHEDULE_FETCH         = Symbol('SCHEDULE_FETCH');
export const SCHEDULE_CHANGE_VALUE  = Symbol('SCHEDULE_CHANGE_VALUE');

export const HEADER_SUBMENU         = Symbol('HEADER_SUBMENU');

export const MEDIA_FETCH            = Symbol('MEDIA_FETCH');
export const MEDIA_FETCH_NEXT       = Symbol('MEDIA_FETCH_NEXT');
export const MEDIA_CREATE           = Symbol('MEDIA_CREATE');
export const MEDIA_UPDATE           = Symbol('MEDIA_UPDATE');
export const MEDIA_DELETE           = Symbol('MEDIA_DELETE');
export const MEDIA_CHANGE_VALUE     = Symbol('MEDIA_CHANGE_VALUE');

export const WIDGET_FETCH            = Symbol('WIDGET_FETCH');
export const WIDGET_FETCH_NEXT       = Symbol('WIDGET_FETCH_NEXT');
export const WIDGET_CREATE           = Symbol('WIDGET_CREATE');
export const WIDGET_UPDATE           = Symbol('WIDGET_UPDATE');
export const WIDGET_DELETE           = Symbol('WIDGET_DELETE');
export const WIDGET_CHANGE_VALUE     = Symbol('WIDGET_CHANGE_VALUE');

export const PLAYLISTS_FETCH        = Symbol('PLAYLISTS_FETCH');
export const PLAYLISTS_FETCH_NEXT   = Symbol('PLAYLISTS_FETCH_NEXT');
export const PLAYLIST_STORE_MEDIA   = Symbol('PLAYLIST_STORE_MEDIA');
export const PLAYLIST_SET_CURRENT   = Symbol('PLAYLIST_SET_CURRENT');
export const PLAYLIST_CHANGE_VALUE  = Symbol('PLAYLIST_CHANGE_VALUE');

export const HIERARCHY_FETCH        = Symbol('HIERARCHY_FETCH');
export const HIERARCHY_CHANGE_VALUE = Symbol('HIERARCHY_CHANGE_VALUE');

export const USER_CHANGE_VALUE = Symbol('USER_CHANGE_VALUE');